@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,600);
@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);
@import url(http://fonts.googleapis.com/css?family=Volkhov:400italic);
/* var text-decoration */
/*--
	Common Css
--*/
body {
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}
h1 {
  font-size: 1.5rem !important;
}
h2 {
  font-size: 1.3rem !important;
}
h3 {
  font-size: 1.2rem !important;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a,
a:active,
a:focus,
a:active {
  text-decoration: none !important;
}

/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

#page-navbar {
/*	padding: 1.0rem 5rem; */
}

#brand {
	color: #5e5e5e;
	font-size: 1.9rem;
}

.section-title {
  margin-bottom: 30px;
}
/* .section-title h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 400;
}
*/
.section-title p {
  font-family: 'Volkhov', serif;
  font-style: italic;
  color: #666;
}

#about {
	padding: 1.0rem 0;
}




/*---------------------------------------------
					Index Start 
-----------------------------------------------*/
/*--
	Slider Start 
--*/
#tagline-banner {
  background: url("../images/sky.jpg") no-repeat; 
  background-size: cover;
  background-attachment: fixed;
  background-position: 20% 0%;
  padding: 0.2rem 0 0.2rem 0;
  position: relative;
}

#tagline-banner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #8b86a3, #322e40);
  opacity: 0.8;
}
#tagline-banner .block {
  color: #E3E3E4;
}
#tagline-banner .block h1 {
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 100 !important;
  line-height: 60px;
  letter-spacing: 10px;
  /* padding-bottom: 45px; */
}
#tagline-banner .block p {
  font-size: 23px;
  line-height: 40px;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 300;
  letter-spacing: 3px;
}



/*--
  contact-section Start
--*/
#contact {
    background: url(../images/nightscape.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 50px 0px;
    background-position: center; 
    text-align: center;
    color: white;
}

#contact a, a:hover, a:visited, a:active, a:focus {
	color:lightgray !important;
}


#contact-section:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #1d192c;
  opacity: 0.8;
}
#contact-section h2 {
  padding-bottom: 20px;
  line-height: 33px;
  margin: 0;
  font-size: 30px;
}
#contact-section p {
  font-size: 14px;
  line-height: 1.6;
}
#contact-section .btn-contact-section {
  padding: 15px 35px;
  border: none;
  background-color: #fff;
  font-size: 15px;
  color: #333333;
  margin-top: 30px;
}



/*---------------------------------------------
					Contact Close 
-----------------------------------------------*/
/*--------- Heading------------*/
.heading {
  padding-bottom: 60px;
  text-align: center;
}
.heading h2 {
  color: #000;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
}
.heading p {
  font-size: 18px;
  line-height: 40px;
  color: #292929;
  font-weight: 300;
}

/*---------------------------*/
/*--
	footer Start 
--*/
footer {
  background: #F5F5F5;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 55px;
}
footer p {
  font-size: 13px;
  line-height: 25px;
  color: #919191;
}
footer a {
  color: #595959;
}
footer .footer-menu {
  padding-bottom: 25px;
}
footer .footer-menu ul {
  margin: 0px;
  padding: 0px;
}
footer .footer-menu ul li {
  display: inline-block;
  padding: 0px 20px;
}
footer .footer-menu ul li a {
  display: inline-block;
  color: #494949;
}
footer .footer-menu ul li a:hover {
  color: #000;
}



@media screen and (max-width: 800px) {

  #tagline-banner .block h1 {
  	font-size: 0.8rem;
    padding-bottom: 0px;
	letter-spacing: 0px;
  }

  #brand {
    font-size: 1.2rem;
  }
}


